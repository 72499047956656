<template>
<div style="height:100%;">
  <a-layout style="height:100%;">
    <a-layout-sider
      collapsible
      v-model:collapsed="collapsed"
      show-trigger
      style="background: rgb(14, 8, 8);">
      <a-menu v-model:selectedKeys="activeKey" mode="vertical" :theme="'dark'">
        <a-menu-item key="NoteList" style="" >
          <template #icon>
            <file-text-outlined style="font-size:20px;"/>
          </template>
          <router-link :to="{ name: 'NoteList', params: {} }">
          笔记
          </router-link>
        </a-menu-item>
        <a-menu-item key="CalendarView" style="" >
          <template #icon>
            <calendar-outlined style="font-size:20px;"/>
          </template>
          <router-link :to="{ name: 'CalendarView', params: {} }">
          日历
          </router-link>
        </a-menu-item>
        <a-menu-item key="TrashedNoteList" style="" >
          <template #icon>
            <delete-outlined style="font-size:20px;"/>
          </template>
          <router-link :to="{ name: 'TrashedNoteList', params: {} }">
          回收站
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout style="height:100%;">
      <a-layout-content>
        <a-space style="width:100%;" direction="vertical">
          <NavBar />
          <TitleBar />
          <div class="h-bar"/>
          <!-- 路由出口 -->
          <!-- 路由匹配到的组件将渲染在这里 -->
          <router-view></router-view>
          <div style="height:50px;">footer</div>
        </a-space>
      </a-layout-content>
    </a-layout>
  </a-layout>
</div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import TitleBar from './components/TitleBar.vue'
import { ref } from "vue";
import { FileTextOutlined, CalendarOutlined, DeleteOutlined } from '@ant-design/icons-vue';

export default {
  name: 'App',
  components: {
    NavBar,
    TitleBar,
    CalendarOutlined, FileTextOutlined, DeleteOutlined
  },
  // data() {
  //   return {
  //     message: 'You loaded this page on ' + new Date().toLocaleString()
  //   }
  // },
  setup() {
    return {
      collapsed: ref(true),
    }
  },
  mounted() {
    console.log(this.$route.name)
    // setInterval(() => {
    //   this.counter++
    // }, 1000)
  },
  computed: {
    activeKey() {
      console.log(this.$route.name)
      return [this.$route.name]
    }
  },
  methods: {
  }
}
</script>

<style>
.n-icon {
  color:white;
  /* color:rgba(1,2,3,4); */
}
.n-divider .n-divider__line {
  height:2px;
}
.n-layout-scroll-container {
  height:100%;
}
/* .n-layout-scroll-container {
  padding:0;
} */
</style>
<style scoped>
.h-bar {
  height:1px;
  width:auto;
  background-color:gray;
  margin-left:5px;
  margin-right:5px;
}
</style>
<style scoped>

</style>
