import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
// import naive from 'naive-ui'
import router from './router'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

// const router = createRouter({
//     scrollBehavior: () => ({ y: 0 }),
//     // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
//     history: createWebHashHistory(),
//     routes: routes
// });
const pinia = createPinia()
createApp(App).use(pinia).use(Antd).use(router).mount('#app')
