import http from '@/http'


export function apiGetYears(params) {
  return http({
    url: '/api/calendar/years',
    method: 'get',
    params: params
  })
}

export function apiGetMonths(params) {
  return http({
    url: '/api/calendar/year-months',
    method: 'get',
    params: params
  })
}

export function apiGetDays(params) {
  return http({
    url: '/api/calendar/days',
    method: 'get',
    params: params
  })
}
