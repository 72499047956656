import http from '@/http'

export function apiGetMyProfile() {
  return http({
    url: '/api/user/my/profile',
    method: 'get'
  })
}

export function apiPutMyProfile(data) {
  return http({
    url: '/api/user/my/profile',
    method: 'put',
    data
  })
}

