import http from '@/http'

export function apiPostNote(data) {
  return http({
    url: '/api/note',
    method: 'post',
    data
  })
}

export function apiGetNotes(params) {
  return http({
    url: '/api/notes',
    method: 'get',
    params: params
  })
}

export function apiPutNote(data) {
  return http({
    url: '/api/note',
    method: 'put',
    data
  })
}

export function apiDeleteNotes(data) {
  return http({
    url: '/api/notes',
    method: 'delete',
    data
  })
}

export function apiGetTrashedNotes(params) {
  return http({
    url: '/api/notes/trashed',
    method: 'get',
    params: params
  })
}

export function apiTrashNotes(data) {
  return http({
    url: '/api/notes/trash',
    method: 'post',
    data
  })
}

export function apiUntrashNotes(data) {
  return http({
    url: '/api/notes/untrash',
    method: 'post',
    data
  })
}
