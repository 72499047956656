<template>
  <div style="">
    <div class="search-bar">
      <div style="float:left;display:inline-block;margin-top:12px;margin-left:10px;">
        <a-radio-group v-model:value="showType"
          button-style="solid"
          @change="onShowTypeBtnChanged">
          <a-radio-button
            v-for="button in showTypeRadioButtons"
            v-bind:key="button.value"
            :value="button.value"
          >
            {{ button.label }}
          </a-radio-button>
        </a-radio-group>
      </div>

      <span style="margin-top:0px;margin-top:12px;margin-left:10px;float:left;font-size:20px;">共找到 {{ total }} 篇笔记</span>
      <a-date-picker
        v-model:value="year"
        picker="year"
        v-show="showType=='months'"
        @change="onYearChanged"
        style="margin-top:0px;margin-top:12px;margin-left:10px;float:left;font-size:20px;width:120px;"/>
    </div>
    <div class="h-bar"/>
    <a-layout style="min-height:calc(100vh - 340px);padding:10px;">
      <a-layout-content style="padding: 24px;width:100%;">
        <a-calendar v-model:value="cday" @panelChange="onPanelChanged" v-show="showType!='years'">
          <template #dateCellRender="{ current }">
            {{ getDayCount(current) }}
          </template>
          <template #monthCellRender="{ current }">
            {{ getMonthCount(current) }}
          </template>
        </a-calendar>
        <div v-show="showType=='years'">
          <ul style="list-style: none;display:block;">
            <li v-for="(item, index) in yearList" v-bind:key="index" class="list-li">
              <div class="list-header">
                <span class="list-header-info">{{ item.year }}</span>
                <span class="list-header-info">|</span>
                <span class="list-header-info"></span>
              </div>
              <div class="list-content">
                {{ item.total }}
              </div>
            </li>
          </ul>
        </div>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
// import { defineComponent } from "vue";
import { ref } from "vue";
import { message } from 'ant-design-vue';
import { apiGetYears, apiGetMonths, apiGetDays } from "@/apis/calendar/calendar";
import { sprintf } from "@/utils/sprintf";
import 'dayjs/locale/zh-cn'
import dayjs from 'dayjs'

export default {
  components: {
  },
  data() {
      return {
        cday: (new dayjs),
        showType: 'days',
        showTypeRadioButtons: [
          {
            key: 'years',
            value: 'years',
            label: '年'
          },
          {
            key: 'days',
            value: 'days',
            label: '日'
          }
        ],
        total: 0,
        year: (new dayjs),
      }
  },
  setup() {
    return {
      dayList: ref(new Map),
      monthList: ref(new Map),
      yearList: ref([]),
    };
  },
  mounted() {
    this.getDayList()
  },
  methods: {
    formatDay(year, month, day){
      return sprintf("%d-%d-%02d", year, month, day)
    },
    showDayInfo(year, month, day){
      const date = sprintf("%d-%d-%02d", year, month, day)
      if (this.dayList.has(date)) {
        return this.dayList.get(date)['total']
      }
      return 0
    },
    onShowTypeBtnChanged() {
      if (this.showType == 'years') {
        this.getYearList()
      } else if (this.showType == 'months') {
        this.getMonthList()
      } else if (this.showType == 'days') {
        this.getDayList()
      }
    },
    handleUpdateValue(_, { year, month, date }) {
      message.success(`${year}-${month}-${date}`);
    },
    getYearList() {
      apiGetYears().then(response => {
        this.yearList = response.data.list
      }).catch(err => {
        message.error(err.message)
        console.log(err)
      })
    },
    getMonthList() {
      const params = {
        year: this.year.format('YYYY')
      }
      apiGetMonths(params).then(response => {
        for (let key in response.data.list) {
          this.monthList.set(key, response.data.list[key])
        }
      }).catch(err => {
        message.error(err.message)
        console.log(err)
      })
    },
    getDayList(month) {
      console.log('xxxxxxxxxxxxxx')
      console.log(month)
      const params = {
        startDate: new dayjs(month+'-01').add(-10, 'day').format('YYYY-MM-DD'),
        endDate: new dayjs(month+'-01').add(40, 'day').format('YYYY-MM-DD'),
      }
      apiGetDays(params).then(response => {
        for (let key in response.data.list) {
          this.dayList.set(key, response.data.list[key])
        }
        // response.data.list.forEach(function(item,index){
        //   this.dayList.set(index, item)
        //   console.log(index)
        //   console.log(item)
        // })
        // console.log(this.dayList)
        // console.log(response.data.list)
      }).catch(err => {
        message.error(err.message)
        console.log(err)
      })
    },
    onYearChanged() {
      this.getMonthList()
    },
    onPanelChanged(value, mode) {
      if (mode === 'month') {
        this.getDayList(value.format('YYYY-MM'))
      }
      if (mode === 'year') {
        this.getMonthList()
      }
      console.log(value, mode)
    },
    getDayCount(day) {
      const date = day.format('YYYY-MM-DD')
      if (this.dayList.has(date)) {
        return this.dayList.get(date)['total']+'篇'
      }
      return 0+'篇'
    },
    getMonthCount(month) {
      const date = month.format('YYYY-MM')
      if (this.monthList.has(date)) {
        return this.monthList.get(date)['total']+'篇'
      }
      return 0+'篇'
    },
  },
  computed: {
      showXXX() {
        // return true
        // 是否部分选择
        return true
      },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.list-li {
  margin:5px;
  padding:0px;
  display:block;
  float:left;
  width:400px;
  border:solid;
  border-width:1px;
  border-color:lightgray;
  border-width:1px;
  border-radius:5px;
}
.list-li:hover {
  box-shadow: 3px 3px 5px 2px rgb(200, 200, 200);
  cursor:pointer;
  background-color:rgb(240, 240, 240);
}
.list-header {
  width:100%;
  height:50px;
  text-align:center;
  border-bottom:solid;
  border-width:1px;
  border-color:lightgray;
}
.list-header-info {
  margin-left:15px;
  margin-top:15px;
  float:left;
}
.list-content {
  height:100px;
}
.search-bar {
  height:55px;
  width:auto;
  color:black;
}
.h-bar {
  height:1px;
  width:auto;
  background-color:gray;
  margin-top:10px;
  margin-left:5px;
  margin-right:5px;
}
</style>
