<template>
  <a-space vertical>
  </a-space>
</template>

<script>
// import { defineComponent } from "vue";
import { apiLogout } from "@/apis/user/login";
import Cookies from "js-cookie";
import { defineStoreProfile } from "@/stores/profile";

export default {
  data() {
      return {
      }
  },
  setup() {
    const storeProfile = defineStoreProfile()
    return {
      storeProfile,
      // username:'root',
      // password:'password',
      info() {
        // message.info("I don't know why nobody told you how to unfold your love", {
        //   keepAliveOnHover: true
        // });
      },
      // error() {
      //   message.error("Once upon a time you dressed so fine");
      // },
      warning() {
        // message.warning("How many roads must a man walk down");
      },
      success() {
        // message.success("'Cause you walked hand in hand With another man in my place");
      },
      loading() {
        // message.loading("If I were you, I will realize that I love you more than any other guy");
      }
    };
  },
  mounted() {
    apiLogout()
    Cookies.set('accessToken', '')
    this.storeProfile.$reset()
    this.$router.push(
      {
        name: 'UserLogin',
        params: {
        }
      }
    )
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
