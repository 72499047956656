import http from '@/http'

export function apiLogin(data) {
    return http({
        url: '/api/user/login',
        method: 'post',
        data
      })
}

export function apiLogout(data) {
  return http({
    url: '/api/user/logout',
    method: 'post',
    data
  })
}

