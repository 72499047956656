<template>
  <a-space style="height:calc(100vh - 120px);">
    <div style="margin-top:calc(10vh);margin-left:auto;margin-right:auto;width:400px;clear:both;">
      <h1>注册新用户</h1>
      <div>Email</div>
      <a-input v-model:value="email" type="text" placeholder="Email" style="margin-top:10px;"/>
      <div style="margin-top:10px;">密码</div>
      <a-input-password
        v-model:value="password"
        placeholder="密码"
        style="margin-top:10px;"
        :maxlength="10"
      />
      <div style="margin-top:10px;">重复密码</div>
      <a-input-password
        v-model:value="passwordRepeat"
        placeholder="密码"
        style="margin-top:10px;"
        :maxlength="10"
      />
      <a-button @click="onClickRegister" style="margin-top:10px;">注册</a-button>
    </div>
  </a-space>
</template>

<script>
// import { defineComponent } from "vue";
import { validateEmail } from "@/utils/validator";
import { apiRegisterEmail } from "@/apis/user/user";
// import Cookies from "js-cookie";
import { message } from 'ant-design-vue';

export default {
  data() {
      return {
          email: '',
          password: '',
          passwordRepeat: '',
      }
  },
  setup() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    error() {
      message.error("")
    },
    onClickRegister() {
      this.email = this.email.trim()
      this.password = this.password.trim()
      this.passwordRepeat = this.passwordRepeat.trim()

      if (!validateEmail(this.email)) {
        message.error('邮箱格式错误')
        return
      }
      if (this.password === '') {
        message.error('密码不得为空')
        return
      }
      if (this.password !== this.passwordRepeat) {
        message.error('两次密码不相同')
        return
      }
        const params = {
            email: this.email,
            password: this.password
        }
        console.log(params)
        apiRegisterEmail(params).then(response => {
          console.log(response)
          // if (response.data.role !== 'Guest') {
          //   console.log(response.data.accessToken)
          //   Cookies.set('accessToken', response.data.accessToken)
          //   this.$router.push(
          //     {
          //       name: 'TranslationList',
          //       params: {
          //       }
          //     }
          //   )
          // }
        }).catch(err => {
          message.error(err.message)
          console.log(err)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
