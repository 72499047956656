<template>
  <div style="">
    <div class="search-bar">
      <a-date-picker
        allowClear
        :disabled="searchDatePickerDisabled"
        v-model:value="searchDate"
        @change="onChangeSearchDate"
        type="date"
        style="float:left;margin-left:5px;margin-top:10px;"
      />
      <a-input
        v-model:value="listQuery.sw"
        :disabled="searchInputDisabled"
        placeholder="搜索"
        @keyup.enter="handleClickSearch"
        style="width:200px;display:block;float:left;margin-top:10px;margin-left:10px;"
      />
      <a-tooltip>
        <template #title>写新笔记</template>
          <a-button
            strong
            @click="handleNewNote"
            style="margin-top:0px;margin-top:10px;margin-left:10px;float:left;">
            <plus-circle-outlined class="icon-plain"/>
          </a-button>
      </a-tooltip>
      <span style="margin-top:0px;margin-top:12px;margin-left:10px;float:left;font-size:20px;">共找到 {{ total }} 篇笔记</span>
    </div>
    <div class="h-bar"/>
    <a-layout style="min-height:calc(100vh - 340px);padding:10px;">
      <a-layout-sider
        :trigger="null"
        collapsible
        :collapsedWidth="0"
        v-model:collapsed="siderCollapsed"
        width="100%"
        style="background-color:white;"
      >
        <div v-show="showPagination" style="clear:both;margin-top:10px;margin-left:10px;">
          <a-pagination
            v-model:current="listQuery.page"
            :page-size="listQuery.limit"
            :total="listQuery.total"
            :pageSizeOptions="['10', '20', '50', '100']"
            showSizeChanger
            @change="onPageChanged"
            style="float:left" />
        </div>
        <div style="clear:both;margin-top:10px;margin-left:10px;">
          <ul style="list-style: none;display:block;">
            <li v-for="(note, index) in list" v-bind:key="index" class="list-li" style="">
              <div class="list-header" style="" @click="onClickCard(index)">
                <span class="list-header-datetime" >{{ note.noteDateTime }}</span>
                <span class="list-header-datetime">|</span>
                <span class="list-header-datetime" :style="weekdayStyle(note.noteDateTime)">{{ printWeekday(note.noteDateTime) }}</span>
                <a-tooltip>
                  <template #title>删除</template>
                    <delete-outlined class="icon-trash" @click.stop="onClickDeleteNote(note)" style="margin-top:15px;margin-right:5px;float:right;"/>
                </a-tooltip>
              </div>
              <a-popover>
                <template #content>
                  <a-list size="small" :data-source="note.tags" :bordered="false">
                    <template #renderItem="{ item }">
                      <a-list-item class="tag-list" @click="onClickTagList(item)">{{ item }}</a-list-item>
                    </template>
                  </a-list>
                </template>
                <div class="list-tags" style="margin-top:20px;">
                    {{ note.tags.join(', ') }}
                </div>
              </a-popover>

              <div class="list-ellipsis" style="" @click="onClickCard(index)">
                <span style="display:inline-block;margin:10px;height:100px;">
                  {{ note.ellipsis }}
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div v-show="showPagination" style="clear:both;margin-top:15px;margin-left:10px;">
          <a-pagination
            v-model:current="listQuery.page"
            :page-size="listQuery.limit"
            :total="listQuery.total"
            :pageSizeOptions="['10', '20', '50', '100']"
            showSizeChanger
            @change="onPageChanged"
            style="float:left" />
        </div>
      </a-layout-sider>
      <a-layout>
      <a-layout-content>
        <new-update-note
          @noteSaved="onNoteSaved"
          @noteDeleted="onNoteDeleted"
          @noteCanceled="onNoteCanceled"
          :propRefreshTime="propRefreshTime" :propNote="propNote" style="width:100%;height:100%;"></new-update-note>
      </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
// import { defineComponent } from "vue";
import { ref } from "vue";
import { apiGetNotes, apiTrashNotes } from "@/apis/note/note";
import NewUpdateNote from "@/components/NewUpdateNote.vue";
import { PlusCircleOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { createVNode } from 'vue';
import { Modal } from 'ant-design-vue';
import 'dayjs/locale/zh-cn'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'

export default {
  components: {
    'new-update-note': NewUpdateNote,
    DeleteOutlined,
    PlusCircleOutlined
  },
  data() {
      return {
        listQuery: {
          page: 1,
          totalPage: 0,
          limit: 10,
          order: 'descend',
          orderBy: '',
          noteDate: '',
          // : [],
        },
        total: 0,
        // createParams: {
        //   username: '',
        //   password: '',
        // },
        // selectedIds: [],
        // note: {
        //   id:0,
        //   noteDateTime: '2000-01-01 00:00:00',
        //   tags: [],
        //   content: '',
        // },
        propNote: {
          id: 0,
          tags: [],
          noteDateTime: '',
          content: '',
        },
        showNewUpdateModal: false,
        propRefreshTime: '',
        siderCollapsed: false,
      }
  },
  setup() {
    return {
      rowKey: (row) => row.id,
      list: ref([]),
      searchDate: ref(),
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    printWeekday(noteDateTime) {
      dayjs.locale('zh-cn')
      const day = (new dayjs(noteDateTime)).day()
      dayjs.extend(localeData)
      return dayjs.weekdays()[day]
    },
    onPageChanged(page, pageSize) {
      this.listQuery.page = page
      this.listQuery.limit = pageSize
      this.getList()
    },
    onPageSizeChanged(pageSize) {
      this.listQuery.page = 1
      this.listQuery.limit = pageSize
      this.getList()
    },
    onSorterChanged(sorter) {
      // 排序
      for(let i=0; i<this.columns.length; i++) {
        let column = this.columns[i]
        if (column.key == sorter.columnKey) {
          column.sortOrder = sorter.order
          this.listQuery.orderBy = column.key
          this.listQuery.order = sorter.order
          this.getList()
          break
        }
      }
    },
    onClickCardTag(tag) {
      this.listQuery.sw = tag
      this.getList()
    },
    handleClickSearch() {
      this.getList()
    },
    getList() {
      apiGetNotes(this.listQuery).then(response => {
        this.listQuery.page = response.data.page
        this.listQuery.totalPage = response.data.totalPage
        this.listQuery.limit = response.data.limit
        this.total = response.data.total
        this.list = []
        for (var i=0; i<response.data.list.length; i++) {
          this.list.push(response.data.list[i])
        }
      }).catch(err => {
        message.error(err.message)
        console.log(err)
      })
    },
    onClickCard(index) {
      this.propNote = this.list[index]
      this.showNewUpdateModal = true
      this.propRefreshTime = new Date()
      this.siderCollapsed = true
    },
    handleNewNote() {
      this.propNote = {
        id: 0,
        tags: [],
        noteDateTime: (new dayjs).format('YYYY-MM-DD HH:mm:ss'),
        content: '',
      }
      this.showNewUpdateModal = true
      this.propRefreshTime = new Date()
      this.siderCollapsed = true
    },
    onClickDeleteNote(note) {
      const that = this
      Modal.confirm({
        title: '警告',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', {
          style: 'color:red;',
        }, '确定删除？'),
        okText: '是的',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          const params = {
            'ids': [note.id],
          }
          apiTrashNotes(params).then(response => {
            message.success(
              "删除成功"
            );
            if (response.data.count > 0) {
              that.getList()
            }
          }).catch(err => {
            message.error(err.message)
            console.log(err)
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    onNoteSaved() {
      this.siderCollapsed = false
      this.getList()
      // alert('onNoteSaved')
    },
    onNoteDeleted() {
      this.siderCollapsed = false
      this.getList()
    },
    onNoteCanceled() {
      this.siderCollapsed = false
    },
    onChangeSearchDate() {
      // 搜索日期变化
      if (this.searchDate == null) {
        this.listQuery.noteDate = ''
      } else {
        this.listQuery.noteDate = this.searchDate.format('YYYY-MM-DD')
      }
      this.getList()
    },
    onSearchInputClear() {
      this.listQuery.sw = ''
      this.getList()
    },
    weekdayStyle(datetime) {
      // 根据是星期几显示不同颜色
      const day = (new dayjs(datetime)).day()
      if (day == 0) {
        return { color: "OrangeRed" }
      } else if (day == 6) {
        return { color: "DarkGreen" }
      } else {
        return { color: "black" }
      }
    },
    onClickTagList(tag) {
      this.listQuery.sw = tag
      this.getList()
    },
  },
  computed: {
      showXXX() {
        // return true
        // 是否部分选择
        return true
      },
      searchDatePickerDisabled() {
        return this.siderCollapsed
      },
      searchInputDisabled() {
        return this.siderCollapsed
      },
      showPagination() {
        return this.list.length > 0
      }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.list-li {
  margin:5px;
  padding:0px;
  display:block;
  float:left;
  width:400px;
  /* height:500px; */
  border:solid;
  border-width:1px;
  border-color:lightgray;
  border-width:1px;
  border-radius:5px;
  /* background-color:gray; */
}
.list-li:hover {
  box-shadow: 3px 3px 5px 2px rgb(200, 200, 200);
  cursor:pointer;
  background-color:rgb(240, 240, 240);
}
.list-header {
  width:100%;
  height:50px;
  text-align:center;
  border-bottom:solid;
  border-width:1px;
  border-color:lightgray;
}
.list-header-datetime {
  margin-left:15px;
  margin-top:15px;
  float:left;
}
.list-tags {
  width:100%;
  height:auto;
  min-height:45px;
  border-bottom:solid;
  border-width:1px;
  border-color:lightgray;
  text-align:left;
}
.list-ellipsis {
  width:100%;
  min-height:45px;
  text-align:left;
}
.li-tag {
  float:left;
  height:40px;
  margin:5px 5px;
}
.n-tag:hover {
  cursor:pointer;
}
.icon-delete {
  color:black;
}
.icon-delete:hover {
  color:red;
}
.search-bar {
  height:55px;
  width:auto;
  margin-left:5px;
  margin-right:5px;
  color:black;
  /* background-color:rgb(4,8,8); */
}
.h-bar {
  height:1px;
  width:auto;
  background-color:gray;
  margin-top:10px;
  margin-left:5px;
  margin-right:5px;
}
.icon-plain {
  color:black;
  font-size:20px;
}
.icon-plain:hover {
  color:green;
}
.icon-trash {
  color:black;
  font-size:20px;
}
.icon-trash:hover {
  color:red;
}
.tag-list:hover {
  cursor:pointer;
  background-color:lightgray;
}
</style>
