<template>
    <n-space vertical size="large">
    <n-layout>
      <n-layout-header>
        <NavBar />
      </n-layout-header>
      <n-layout-content style="padding: 24px;width:100%;">
        <!-- 路由出口 -->
        <!-- 路由匹配到的组件将渲染在这里 -->
        <router-view></router-view>
        <div style="height:50px;">footer</div>
      </n-layout-content>
    </n-layout>
    </n-space>
</template>

<script>
import NavBar from './components/NavBar.vue'
import { onMounted } from 'vue'

export default {
  name: 'App',
  components: {
    NavBar
  },
  // data() {
  //   return {
  //     message: 'You loaded this page on ' + new Date().toLocaleString()
  //   }
  // },
  setup() {
    // 页面第一次打开
    onMounted(() => {
      console.log('Component is mounted!')
    })
  },
  mounted() {
    console.log(this.$route.name)
    // setInterval(() => {
    //   this.counter++
    // }, 1000)
  },
  methods: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
