<template>
  <div class="newnote" style="height:100%;width:100%;">
    <!-- <n-space vertical style="text-align:left;height:90%;width:90%;" > -->
      <div class="" style="height:40px;">
        <a-tooltip>
          <template #title>返回列表</template>
            <a-button
              strong
              @click="handleClickClose"
              style="margin-top:0px;margin-left:10px;float:left;">
              <double-right-outlined class="icon-plain" style=""/>
            </a-button>
        </a-tooltip>
        <a-tooltip>
          <template #title>保存</template>
          <a-button
            strong
            @click="handleClickSaveNote"
            style="margin-top:0px;margin-left:10px;float:left;">
            <save-outlined class="icon-plain" style=""/>
          </a-button>
        </a-tooltip>
        <a-tooltip>
          <template #title>删除</template>
          <a-button
            :disabled="deleteBtnDisabled"
            strong
            @click="handleClickDeleteNote"
            style="margin-top:0px;margin-left:10px;float:left;">
            <delete-outlined class="icon-trash" style=""/>
          </a-button>
        </a-tooltip>
        <a-tooltip>
          <template #title>选择笔记时间</template>
          <a-date-picker
            :allowClear="false"
            show-time
            v-model:value="noteDateTime"
            @change="onChangeDateTime"
            style="width:200px;float:left;margin-left:5px;"
          />
        </a-tooltip>
        <div class="v-bar" style=""></div>
        <div style="display:inline-block;float:left;margin-top:10px;">
          <tag-outlined class="icon-plain" />
        </div>
        <div style="display:inline-block;float:left;margin-top:5px;margin-left:10px;">
          <template v-for="(tag, index) in note.tags" :key="index">
            <a-tooltip v-if="tag.length > 20" :title="tag">
              <a-tag class="tag" :closable="true" @close="handleTagClose(tag)">
                {{ `${tag.slice(0, 20)}...` }}
              </a-tag>
            </a-tooltip>
            <a-tag class="tag" v-else :closable="true" @close="handleTagClose(tag)">
              {{ tag }}
            </a-tag>
          </template>
          <a-input
            v-if="tagInputVisible"
            ref="inputRef"
            v-model:value="tagInputValue"
            type="text"
            size="small"
            style=""
            class="tag-input"
            @blur="handleTagInputConfirm"
            @keyup.enter="handleTagInputConfirm"
          />
          <a-tag class="tag" v-else style="background: #fff; border-style: dashed" @click="onClickShowTagInput">
            新标签
            <plus-outlined />
          </a-tag>
        </div>
      </div>
      <div class="" style="margin-top:10px;width:100%;height:90%;">
        <a-textarea
          v-model:value="note.content"
          placeholder=""
          style="text-align:left;width:100%;height:90%;"
        />
      </div>
    <!-- </n-space> -->
    
  </div>
</template>

<script>
// import { defineComponent } from "vue";
// import dayjs from 'dayjs'
import { nextTick } from "vue";
// import { NIcon } from "naive-ui";
// import { h } from "vue";
// import { renderIcon } from "@/utils/utils";
import { apiPostNote, apiPutNote, apiDeleteNotes } from "@/apis/note/note";
// import moment from "moment"
import dayjs from 'dayjs'
import { PlusOutlined, TagOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons-vue';
import { ExclamationCircleOutlined, DoubleRightOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { createVNode } from 'vue';
import { Modal } from 'ant-design-vue';

export default {
  props: ['propRefreshTime', 'propNote'],
  components: {
    PlusOutlined, TagOutlined, DeleteOutlined, SaveOutlined, DoubleRightOutlined
  },
  data() {
    return {
      noteDateTime: (new dayjs),
      addTag: '',
      tagInputVisible: false,
      tagInputValue: '',
      note: {
        id: 0,
        noteDateTime: '',
        content:'',
        tags: [],
      },
    }
  },
  setup() {
    return {
    };
  },
  computed: {
    addTagDisabled() {
      return this.note.tags.length >= 10
    },
    deleteBtnDisabled() {
      return this.note.id == 0
    }
  },
  mounted() {
  },
  watch:{ 
    propRefreshTime(/*newVal, oldVal*/) {
        this.refreshNote()
    },
    propNote:{
      handler(/*newVal, oldVal*/) {
        this.refreshNote()
      }
    },
  },
  methods: {
    error() {
      message.error("")
    },
    onChangeDateTime() {
      this.note.noteDateTime = this.noteDateTime.format('YYYY-MM-DD HH:mm:ss')
      // console.log(this.note.noteDateTime)
    },
    handleClickSaveNote() {
      // 保存笔记按钮
      if (this.note.id > 0) {
        apiPutNote(this.note).then((/*response*/) => {
          // console.log(response)
          this.$emit('noteSaved', {})
        }).catch(err => {
          message.error(err.message)
          console.log(err)
        })
      } else {
        apiPostNote(this.note).then(response => {
          this.note.id = response.data.id
          this.note.content = response.data.content
          this.note.noteDateTime = response.data.noteDateTime
          this.note.tags = response.data.tags
          this.noteDateTime = new dayjs(this.note.noteDateTime)
          this.$emit('noteSaved', {})
        }).catch(err => {
          message.error(err.message)
          console.log(err)
        })
      }
    },
    handleClickDeleteNote() {
      // 删除笔记按钮
      const that = this
      Modal.confirm({
        title: '警告',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', {
          style: 'color:red;',
        }, '确定删除？'),
        okText: '是的',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          const params = {
            'ids': [that.note.id],
          }
          apiDeleteNotes(params).then((/*response*/) => {
            message.success(
              "删除成功"
            );
            that.$emit('noteDeleted', {})
          }).catch(err => {
            message.error(err.message)
            console.log(err)
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    handleClickClose() {
      this.$emit('noteCanceled', {})
    },
    refreshNote() {
      this.note = JSON.parse(JSON.stringify(this.propNote))
      this.noteDateTime = new dayjs(this.note.noteDateTime)
    },
    handleTagClose(removedTag) {
      const tags = this.note.tags.filter(tag => tag !== removedTag);
      this.note.tags = tags;
    },
    handleTagInputConfirm() {
      const inputValue = this.tagInputValue;
      let tags = this.note.tags;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      this.note.tags = tags
      this.tagInputValue = ''
      this.tagInputVisible = false;
    },
    onClickShowTagInput() {
      this.tagInputVisible = true;
      nextTick(() => {
        this.$refs.inputRef.focus();
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon-trash {
  color:black;
  font-size:20px;
}
.icon-trash:hover {
  color:red;
}
.icon-plain {
  color:black;
  font-size:20px;
}
.icon-plain:hover {
  color:green;
}
.v-bar{
  height:90%;
  width:1px;
  background-color:lightgray;
  margin-left:5px;
  margin-right:5px;
  float:left;
}
.tag {
  font-size:18px;
  padding:4px;
}
.tag-input {
  width:100px;
  font-size:16px;
  padding:2px;
}
</style>
