<template>
  <div class="titlebar" style="height:100px;">
    <img :src="logoImg" style="float:left;margin:5px;width:180px;height:100px;">
  </div>
</template>

<script>
// import { defineComponent } from "vue";
// import { NIcon } from "naive-ui";
import LogoImg from '@/assets/logo.png'
export default {
  components: {
  },
  data() {
    return {
      // activeKey: 'home'
    }
  },
  setup() {
    return {
      logoImg: LogoImg,
      info() {
        // message.info("I don't know why nobody told you how to unfold your love", {
        //   keepAliveOnHover: true
        // });
      },
      // error() {
      //   message.error("Once upon a time you dressed so fine");
      // },
      warning() {
        // message.warning("How many roads must a man walk down");
      },
      success() {
        // message.success("'Cause you walked hand in hand With another man in my place");
      },
      loading() {
        // message.loading("If I were you, I will realize that I love you more than any other guy");
      },
    };
  },
  computed: {
    activeKey() {
      console.log(this.$route.name)
      return this.$route.name
    }
  },
  methods: {
    error() {
    },
    handleUpdateValue (key, item) {
        console.log(key, item)
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.titlebar {
  /* background: rgb(190, 186, 186); */
  color:white;
  float:left;
  height:100px;
  width:100%;
  padding: 0;
}

</style>
