// import VueRouter from 'vue-router'
// const About = { template: '<div>About</div>' }
// import HomePage from '../components/HomePage.vue'
// import NavBar from '../components/NavBar.vue'
import UserLogin from '../views/user/UserLogin.vue'
import UserLogout from '../views/user/UserLogout.vue'
import MainPage from '../views/note/NoteList.vue'
import UserRegister from '../views/user/UserRegister.vue'
import NoteList from '../views/note/NoteList.vue'
import TrashedNoteList from '../views/note/TrashedNoteList.vue'
import CalendarView from '../views/note/CalendarView.vue'
import NeatLayout from '../views/layout/NeatLayout.vue'
import MainLayout from '../views/layout/MainLayout.vue'

import {createRouter, createWebHashHistory} from 'vue-router'
// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
  {
    name: 'MainPage',
    path: '/',
    component: MainLayout,
    children: [
      { name: 'MainPage', path: '/', component: MainPage },
    ],
  },
  {
    name: 'UserParent',
    path: '/user',
    component: NeatLayout,
    children: [
      { name: 'UserLogin', path: '/user/login', component: UserLogin },
      { name: 'UserLogout', path: '/user/logout', component: UserLogout },
      { name: 'UserRegister', path: '/user/register', component: UserRegister },
    ],
  },
  {
    name: 'NoteParent',
    path: '/note',
    component: MainLayout,
    children: [
      { name: 'NoteList', path: '/note/list', component: NoteList },
      { name: 'CalendarView', path: '/note/calendar', component: CalendarView },
      { name: 'TrashedNoteList', path: '/note/trashed', component: TrashedNoteList },
    ],
  },
]

// export default new VueRouter({
//     scrollBehavior: () => ({ y: 0 }),
//     // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
//     history: VueRouter.createWebHashHistory(),
//     routes: routes
// });

const router = createRouter({
  scrollBehavior: () => ({ y: 0 }),
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes: routes
});
export default router;
