<template>
  <a-config-provider :locale="locale">
    <a-layout style="height:100%;">
      <a-layout-content style="height:100%;width:100%;">
        <!-- 路由出口 -->
        <!-- 路由匹配到的组件将渲染在这里 -->
        <router-view style="height:100%;"></router-view>
      </a-layout-content>
    </a-layout>
  </a-config-provider>
</template>

<script>
// import { useMessage } from "naive-ui";
import { apiGetMyProfile } from "@/apis/user/profile";
import { defineStoreProfile } from "@/stores/profile";
import { onMounted } from 'vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { message } from 'ant-design-vue';

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      locale: zhCN,
    }
  },
  setup() {
    // 页面第一次打开
    onMounted(() => {
      console.log('Component is mounted!')
    })
    const storeProfile = defineStoreProfile()
    return {
      storeProfile
    }
  },
  mounted() {
    // console.log(this.$route.name)
    this.getMyProfile()
    // setInterval(() => {
    //   this.counter++
    // }, 1000)
  },
  methods: {
    getMyProfile() {
      apiGetMyProfile().then(response => {
        this.storeProfile.saveProfile(response.data)
        // console.log(response)
        if (response.data.role == 'Guest') {
          this.$router.push(
            {
              name: 'UserLogin',
              params: {
              }
            }
          )
        }
      }).catch(err => {
        message.error(err.message)
        console.log(err)
        this.$router.push(
          {
            name: 'UserLogin',
            params: {
            }
          }
        )
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  height: 100%;
}
html {
  height:100%;
}
body {
  height:100%;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 5px;
}
h3 {
  margin: 40px 0 0;
}
a {
  color: #42b983;
}
</style>
