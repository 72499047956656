import axios from 'axios'
import router from '../router'
import { getToken } from "@/utils/token";

// 创建一个 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 所有的请求地址前缀部分
  timeout: 60000, // 请求超时时间毫秒
  withCredentials: true, // 异步请求携带cookie
  headers: {
    // 设置后端需要的传参类型
    'Content-Type': 'application/json',
    // 'token': 'your token',
    'X-Requested-With': 'XMLHttpRequest',
  },
})

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // const accessToken = Cookies.get('accessToken')
    const accessToken = getToken()
    config.headers['X-Auth-Token'] = accessToken
    return config
  },
  function (error) {
    // 对请求错误做些什么
    console.log(error)
    return Promise.reject(error)
  }
)

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    // dataAxios 是 axios 返回数据中的 data
    const data = response.data
    // 这个状态码是和后端约定的
    // const code = dataAxios.reset
    return data
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    console.log(error)
    return Promise.reject(error)
  }
)

export default function http({ url, method, data, params }) {
  return new Promise((resolve, reject) => {
    service({
      url,
      method,
      data,
      params
    }).then(response => {
      const data = response
      // console.log(data)
      if (data.error.code !== 0) {
        var error = data.error
        error.data = data.data
        reject(error)
      }
      resolve(data)
    }).catch(error => {
      // 未登录跳转到登录页面
      if(error.response.status === 403) {
        router.push(
          {
            name: 'UserLogin',
            params: {
            }
          }
        )
        return
      }
      reject(error)
    })
  })
  }
  