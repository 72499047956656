<template>
  <a-space style="height:calc(100vh - 120px);">
    <div style="margin-top:calc(10vh);margin-left:auto;margin-right:auto;width:400px;">
      <h1>账号登录</h1>
      <a-input v-model:value="username" type="text" placeholder="用户名"/>
      <a-input
        type="password"
        v-model:value="password"
        show-password-on="mousedown"
        placeholder="密码"
        style="margin-top:10px;"
        :maxlength="10"
      />
      <div style="margin-top:10px;">
        <a-button @click="onClickLogin">登录</a-button>
        <!-- <a href="#/user/register" style="margin-left:10px;">注册</a>  -->
      </div>
    </div>
  </a-space>
</template>

<script>
// import { defineComponent } from "vue";
// import { apiGetMyProfile } from "@/apis/user/profile";
import { apiLogin } from "@/apis/user/login";
import Cookies from "js-cookie";
import { defineStoreProfile } from "@/stores/profile";
import { message } from 'ant-design-vue';

export default {
  data() {
      return {
          // username: 'cleverysm@163.com',
          // password: '820101'
          username: '',
          password: ''
      }
  },
  setup() {
    const storeProfile = defineStoreProfile()
    return {
      storeProfile
    }
  },
  mounted() {
  },
  methods: {
    error() {
      message.error("")
    },
    onClickLogin() {
        const params = {
            username: this.username,
            password: this.password
        }
        console.log(params)
        apiLogin(params).then(response => {
          // console.log(response)
          this.storeProfile.saveProfile(response.data)
          if (response.data.role !== 'Guest') {
            // console.log(response.data.accessToken)
            Cookies.set('accessToken', response.data.accessToken)
            this.$router.push(
              {
                name: 'NoteList',
                params: {
                }
              }
            )
          }
        }).catch(err => {
          message.error(err.message)
          console.log(err)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
