<template>
  <div class="navbar" style="width:100%;color:black">
    <a-menu v-model:selectedKeys="activeKey" mode="horizontal" :theme="'dark'">
      <a-menu-item key="NoteList" style="padding-top:5px;" >
        <template #icon>
          <home-outlined style="font-size:20px;"/>
        </template>
        <router-link :to="{ name: 'NoteList', params: {} }">
        首页
        </router-link>
      </a-menu-item>
      <a-menu-item key="UserLogout" style="padding-top:5px;" >
        <template #icon>
          <logout-outlined style="font-size:20px;"/>
        </template>
        <router-link :to="{ name: 'UserLogout', params: {} }">
        拜拜
        </router-link>
      </a-menu-item>
    </a-menu>
    <!-- <n-menu
      :value="activeKey"
      mode="horizontal"
      :options="menuOptions"
      @update:value="handleUpdateValue"
      style="--n-item-text-color-horizontal:white;font-weight:bolder;float:left;"
    /> -->
  </div>
</template>

<script>
// import { defineComponent } from "vue";
import { message } from 'ant-design-vue';
import { HomeOutlined, LogoutOutlined } from '@ant-design/icons-vue';
export default {
  components: {
    HomeOutlined, LogoutOutlined
  },
  data() {
    return {
    }
  },
  setup() {
    return {
      // menuOptions,
      info() {
        // message.info("I don't know why nobody told you how to unfold your love", {
        //   keepAliveOnHover: true
        // });
      },
      // error() {
      //   message.error("Once upon a time you dressed so fine");
      // },
      warning() {
        // message.warning("How many roads must a man walk down");
      },
      success() {
        // message.success("'Cause you walked hand in hand With another man in my place");
      },
      loading() {
        // message.loading("If I were you, I will realize that I love you more than any other guy");
      },
    };
  },
  computed: {
    activeKey() {
      // console.log(this.$route.name)
      // return [this.$route.name]
      return []
    }
  },
  methods: {
    error() {
      message.error("")
    },
    // handleUpdateValue (key, item) {
    //     console.log(key, item)
    //     this.activeKey = key
    //   }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.navbar {
  background: #001529;
  color:white;
  float:left;
  width:100%;
}
.icon-plain {
  font-size:40px;
}


</style>
<style>


</style>
